<template>
  <div>
    <b-button-group class="mb-2">
			<b-button class="mr-1" @click="listUsers()">List</b-button>
			<b-button class="mr-1" @click="process()">Process</b-button>
    </b-button-group>

    <debug>
      {{ cognitoUsers }}
    </debug>
  </div>
</template>

<script>
import {
    adminCreateUser,
    adminListUsers,
    adminUpdateUserAttributes,
} from '@/scripts/aws'

export default {
    name: 'AddUserIdAttribute',
    data: () => ({
        cognitoUsers: { loading: false, items: [] },
    }),
    methods: {
        async listUsers() {
            this.cognitoUsers.loading = true
            this.cognitoUsers.items = await adminListUsers()
            this.cognitoUsers.loading = false
        },
        async process() {
            /* eslint-disable no-await-in-loop */
            await this.listUsers();
            const batchSize = 20; // number of users to process in a batch
            const delayBetweenBatches = 1000; // delay between batches (in milliseconds)

            for (let i = 0; i < this.cognitoUsers.items.length; i += batchSize) {
                const batch = this.cognitoUsers.items.slice(i, i + batchSize);
                await Promise.all(batch.map(async (user, index) => {
                    try {
                        const subAttribute = user.Attributes.find(attribute => attribute.Name === 'sub');
                        if (subAttribute) {
                            const attributes = [
                                {
                                    Name: 'custom:user_id',
                                    Value: subAttribute.Value,
                                },
                            ];
                            await adminUpdateUserAttributes(user.Username, attributes);
                        }
                    } catch (error) {
                        console.error(`Error updating user ${user.Username}:`, error.message);
                    }
                }));

                if (i + batchSize < this.cognitoUsers.items.length) {
                    await new Promise((resolve) => setTimeout(resolve, delayBetweenBatches));
                }
            }
        },
    },
}
</script>
